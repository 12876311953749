import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

import Loader from './components/@jobit/Loader/Loader';
import * as serviceWorker from './serviceWorker';

const App = React.lazy(() => import('./App'));
const Mobile = React.lazy(() => import('./pages/Mobile/Mobile'));

function detectDevice() {
  const currentRoute = window.location.pathname.split('/')[1];
  const excludedPaths = new Set(['verify-provider', 'forgot-password', 'terms-of-service', 'privacy-policy']);
  if (isMobile) {
    if (excludedPaths.has(currentRoute)) {
      return <MobileView>
        <Suspense
          fallback={null}
        // fallback={<Loader global="global-loader" />}
        >
          <App />
        </Suspense>
      </MobileView>
    }
    return <MobileView>
      <Suspense fallback={null}>
        <Mobile />
      </Suspense>
    </MobileView>
  } else {
    return <BrowserView>
      <Suspense
        fallback={null}
      // fallback={<Loader />}
      >
        <App />
      </Suspense>
    </BrowserView>
  }
}

function app() {
  return (
    <>
      {detectDevice()}
    </>
  );
}

ReactDOM.render(app(), document.getElementById('root'));
serviceWorker.unregister();
