import React from 'react';

const Loader = ({ userMod, global, marginBottom }) => {

  if (userMod)
    return (
      <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "0" }}>
        <span className="spinner" />
      </div>
    );
  else
    return (
      <div className={`overlay ${global}`}>
        <div className="overlay__inner">
          <div className="overlay__content">
            <span className="spinner" style={{ marginBottom: marginBottom, }} />
          </div>
        </div>
      </div>
    );
};

export default Loader;
